import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import CommonPlan from '../components/common-plan';

const Page = () => {
  const data = useStaticQuery(
    graphql`
    query {
      allStrapiPageData(filter: {page_name: {eq: "life_insurance_landing_page"}}) {
        edges {
          node {
            data_category
            column
            content
            style_category
            attribute_1_value
            attribute_2_value
            attribute_1_key
            attribute_2_key
          }
        }
      }
    }
    `
  );

  return (
    <>
      {data &&
        <CommonPlan data={data} parent={'life'}/>
      }
    </>
  );
};

export default Page;
