import '../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import FaqSection from '../components/faq-row';
import slideImg from '../images/slide1.png';
import Layout from './layout';
import Seo from './seo';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = new Set();
  data.forEach( element => {
    uniqueSectionArray.add( element.node.data_category );
  } );
  return [...uniqueSectionArray];
};

let getformattedData2 = ( data, sectionsArray ) => {
  let output = {};
  let section;
  let column;

  sectionsArray.forEach( sectionValue => {
    output[sectionValue] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionValue && item.node );
    for ( let col = 1; col < 5; col++ ) {
      output[sectionValue]['column_' + col] = {};
      column = section.filter( ( item ) => item.node.column == col && item.node );
      let len = column.length;
      for ( let j = 0; j < len; j++ ) {
        output[sectionValue]['column_' + col][column[j].node.style_category] = column[j].node;
      }

      if ( Object.keys( output[sectionValue]['column_' + col] ).length === 0 ) {
        delete output[sectionValue]['column_' + col];
      }
    }
  } );

  return output;
};

const CommonPlan = ( { data, parent } ) => {
  const [isLifeInsurance, changeCarrier] = useState( true );
  const [formattedData, setFormattedData] = useState();
  const [faqArray, setFaq] = useState( [] );


  useEffect( () => {
    if ( parent === 'home' ) {
      changeCarrier( false );
    }

    let pageData = data.allStrapiPageData.edges;
    let uniqueSectionData = getUniqueSectionData( pageData );
    const formattedData = getformattedData2( pageData, uniqueSectionData );
    setFormattedData( formattedData );

  }, [data] );

  useEffect( () => {
    const faqArray = isLifeInsurance ? [
      {
        question: 'Can I buy only term policies at Bubble?',
        answer: 'At present you can only buy term policies at Bubble. We help you find the right term policy that you’re comfortable with and one that fits your budget. In future, we shall be offering many more products.'
      },
      {
        question: 'How does Life Insurance fit in with my household\'s other financial responsibilities?',
        answer: 'Our smart guidance platform takes into account your other financial responsibilities, your family circumstances and other factors to help you find the most appropriate coverage and term for your policy. The payout can help your family with mortgage payments, or children\'s college tuition, and they can be financially secure even when you are not there anymore. Plan your expenses right and you will be glad to have bought a policy.'
      },
      {
        question: 'Does everybody really need a medical test to get life insurance?',
        answer: 'No. In fact, Bubble partner carriers provide accelerated, fluidless, no-exam underwriting for close to half the applicants in the 25-45 age group.'
      }
    ] : [
      {
        question: 'What does Home Insurance cover?',
        answer: 'Home insurance helps pay for lost or stolen personal belongings and for repairs or rebuilding your home in case of any damage caused by perils like theft, fire, flood, and so on. It may also cover hotel stay if your home is too damaged to live in.'
      },
      {
        question: 'Is it legally mandatory to buy Home Insurance?',
        answer: 'Legally, it is possible to own a home without home insurance, but your mortgage provider may require that you buy it to protect their financial interests.'
      }
    ];

    setFaq( faqArray );
  }, [isLifeInsurance] );

  return (
    <Layout header={'menu'}>
            ${
        isLifeInsurance ?
          <Seo
            title="Life Insurance: Buy Best Life Insurance Plans Online "
            description="Buy life insurance online from Get My Bubble. Offers a range of life insurance plans &amp;
                policies to ensure Lifetime Protection for you &amp; your family. Get quick life insurance Quote Online."
            canonicalUrl="https://www.getmybubble.com/life-insurance/"
            schema={[
              JSON.stringify( {
                '@context': 'http://www.schema.org',
                '@type': 'WebPage',
                'name':'Life Insurance',
                'url': 'https://www.getmybubble.com/life-insurance/',
                'description':'Put your family first with Life Insurance and protect them even when you’re not around anymore. Find out which plan is perfect for you at Bubble Insurance.',
                'publisher': {
                  '@type': 'ProfilePage',
                  'name': 'Bubble Insurance'
                }
              } ),
              JSON.stringify( {
                '@context': 'http://schema.org',
                '@type': 'Product',
                'name': 'Life Insurance',
                'aggregateRating': {
                  '@type': 'AggregateRating',
                  'ratingValue': '4.4 ',
                  'reviewCount': '6' }
              } ),
              JSON.stringify(  {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                'url': 'https://www.getmybubble.com/',
                'logo': 'https://www.getmybubble.com/static/buble-077b4498b854b2301e9cbf5a55b69b3d.svg'
              } )
            ]}
            robots={ { 1:'index', 2: 'follow' } }
            keywords="Best Life Insurance Policy, Life Insurance Quotes Online, Buy Life Insurance online." /> :
          <Seo
            title="Homeowners Insurance: Buy Best Home Insurance Policy &amp; Plans Online"
            description="Buy Homeowners Insurance Online from Get My Bubble. Covers destruction
              &amp; damage to a residence's interior and exterior. Get an instant quote for home. Call 833-900-2822"
            canonicalUrl="https://www.getmybubble.com/homeowners-insurance/"
            robots={ { 1:'index', 2: 'follow' } }
            schema={[
              JSON.stringify( {
                '@context': 'http://www.schema.org',
                '@type': 'WebPage',
                'name':'HomeOwners Insurance',
                'url': 'https://www.getmybubble.com/homeowners-insurance/',
                'description':`Homeowners insurance policies generally cover destruction & damage to a residence's
                            interior and exterior. Check best Homeowners insurance plans & policies at Bubble Insurance.`,
                'publisher': {
                  '@type': 'ProfilePage',
                  'name': 'Bubble Insurance'
                }
              } ),
              JSON.stringify(  {
                '@context': 'http://schema.org',
                '@type': 'Product',
                'name': 'Homeowner’s Insurance',
                'aggregateRating': {
                  '@type': 'AggregateRating',
                  'ratingValue': '4.4 ',
                  'reviewCount': '6'
                } } ),
              JSON.stringify(  {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                'url': 'https://www.getmybubble.com/',
                'logo': 'https://www.getmybubble.com/static/buble-077b4498b854b2301e9cbf5a55b69b3d.svg'
              } )
            ]}
            keywords="Home Insurance Policy, Home insurance online, Home insurance online quotes, Buy home insurance online," />
      };

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="lifehomebg container-fluid d-flex justify-content-center">

          <div className="Menu-first-sec">
            <h1 className="heading">
              {formattedData && <>
                <img src={formattedData.section_1.column_1.title.attribute_1_value}
                  alt={ isLifeInsurance ? 'Buy Life Insurance' : 'Buy Home Insurance' } />

                {formattedData.section_1.column_1.title.content}
              </>}
            </h1>

            <p>
              {formattedData && formattedData.section_1.column_1.card_title_1.content}
            </p>

            <div className="flexRow btns">
              <Link to="/" className="orangeBtn">Get a quote now</Link>

              <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                <button type="button" className="explore"> Explore </button>   </a>
            </div>
          </div>

          {
            isLifeInsurance ?
            // <div className="man-with-baby">
            //     <img src={"https://devuiassetscdn.getmybubble.com/images/group-7-man.png"} className="man-with-baby-img" /></div>
              <div className="man-with-baby">
                <img src={slideImg} className="man-with-baby-img" alt="Bubble Life Insurance" /></div>
              : <div className="house">
                <img src="https://devuiassetscdn.getmybubble.io/images/group-7-house.png" alt="Buy Bubble Home Insurance"/>
              </div>
          }
        </div>

        <div className={`containerCustomStyles whatIsLife ${isLifeInsurance ? 'life' : 'home'}`} id="start-exploring" >
          <div className="infoBox flexRow justify-content-around">
            <div className="flexColumn imgPaddingTop">
              <img src={formattedData && formattedData.section_2.column_1.title.attribute_1_value}
                alt={formattedData && formattedData.section_2.column_1.title.attribute_2_value} />

              <h2 className="section-title">
                {formattedData && formattedData.section_2.column_1.title.content}
              </h2>
            </div>

            <div className="container">
              <div className="vertically-center-para row">
                <div className="col-md-6 column2-text">
                  {formattedData && formattedData.section_2.column_2.card_text.content}
                </div>

                <div className="col-md-6 column2-text text2">
                  {formattedData && formattedData.section_2.column_3.card_text.content}
                </div></div>
            </div>
          </div>
        </div>

        <div className="insuranceCovers flexRow justify-content-center">
          <div className="flexColumn containerCustomStyles">
            <h2 className="section-title">
              {formattedData && formattedData.insurance_cover.column_1.title.content}
            </h2>

            <div className="flexRow coverInfo">
              {formattedData &&
                                Object.keys( formattedData.insurance_cover ).map( e => ( <div key={e} className="coverCard">
                                  <div className="cardTitle">
                                    {formattedData.insurance_cover[e].card_sub_title.content}
                                  </div>

                                  <div className="cardText">
                                    {formattedData.insurance_cover[e].card_text.content}
                                  </div>
                                </div> ) )
              }
            </div>
          </div>
        </div>

        <div className="insurancePolicies flexRow justify-content-center">
          <div className="containerCustomStyles flexColumn">
            <h3 className="section-title">
              {formattedData && formattedData.our_policies.column_1.title.content}
            </h3>

            <div className="flexRow">
              {formattedData &&
                                Object.keys( formattedData.our_policies ).map( ( e, i ) =>
                                  ( ( isLifeInsurance && ( i == 1 ) ) || !isLifeInsurance ) && <div key={e} className={`policyCard flexColumn  ${isLifeInsurance ? 'lifePolicyCard' : ''}`}>
                                    <div className="lightBlueBg">
                                      <img src={formattedData.our_policies[e].card_sub_title.attribute_1_value}
                                        alt={formattedData.our_policies[e].card_sub_title.attribute_2_value} />

                                      <div className="flexRow justify-content-between">
                                        <div className="title">
                                          {formattedData.our_policies[e].card_sub_title.content}
                                        </div>

                                        {!isLifeInsurance &&
                                                    <div className="flexRow align-items-center">
                                                      <div className="grade">{formattedData.our_policies[e].grade.attribute_1_value}</div>

                                                      <div className="gradeText">  {formattedData.our_policies[e].grade.attribute_2_value} </div>
                                                    </div>
                                        }
                                      </div>
                                    </div>

                                    <div className={`flexColumn  policyInfo ${isLifeInsurance ? 'lifePolicy' : ''}`}>
                                      {isLifeInsurance ?
                                        <>
                                          <div className="flexRow justify-content-between policyData">
                                            <div className="flexColum">
                                              <div className="label">
                                                {formattedData.our_policies.column_1.orange_label_1.content}
                                              </div>

                                              <span>
                                                {formattedData.our_policies[e].carrier_data.attribute_1_value}
                                              </span>
                                            </div>

                                            <div className="flexColum">
                                              <div className="label">
                                                {formattedData.our_policies.column_1.orange_label_2.content}
                                              </div>

                                              <span>
                                                {formattedData.our_policies[e].carrier_data.attribute_2_value}
                                              </span>
                                            </div>
                                          </div>

                                          <div className="flexColumn policyHighlight">
                                            <div className="flexColumn" style={{ color: '#2a2727', font: '600 14px Poppins' }}>
                                                            Key Benefits
                                              <div style={{ fontSize: '12px', marginTop: '8px' }}>
                                                {formattedData.our_policies[e].key_benefits.content}
                                              </div>
                                            </div>

                                            <div className="flexColumn">
                                              <div className="flexRow align-items-center">
                                                <div style={{ marginRight: '5px', display: 'block', borderRadius: '50%', background: '#ff7007', width: '6px', height: '6px' }}></div>

                                                <div style={{ color: '#2a2727', font: '600 12px Poppins' }}>
                                                  {formattedData.our_policies[e].card_text_1.content}
                                                </div>
                                              </div>

                                              <div>
                                                <div style={{ color: '#69707c', font: 'normal 12px Lato' }}>{formattedData.our_policies.column_1.card_text_1.attribute_2_value}</div>
                                              </div>
                                            </div>

                                            <div className="flexColumn">
                                              <div className="flexRow align-items-center">
                                                <div style={{ marginRight: '5px', display: 'block', borderRadius: '50%', background: '#ff7007', width: '6px', height: '6px' }}></div>

                                                <div style={{ color: '#2a2727', font: '600 12px Poppins' }}>
                                                  {formattedData.our_policies[e].card_text_2.content}
                                                </div>
                                              </div>

                                              <div>
                                                <div style={{ color: '#69707c', font: 'normal 12px Lato' }}>{formattedData.our_policies.column_1.card_text_2.attribute_2_value}</div>
                                              </div>
                                            </div>

                                            <div className="flexColumn">
                                              <div className="flexRow align-items-center">
                                                <div style={{ marginRight: '5px', display: 'block', borderRadius: '50%', background: '#ff7007', width: '6px', height: '6px' }}></div>

                                                <div style={{ color: '#2a2727', font: '600 12px Poppins' }}>
                                                  {formattedData.our_policies[e].card_text_3.content}
                                                </div>
                                              </div>

                                              <div>
                                                <div style={{ color: '#69707c', font: 'normal 12px Lato' }}>{formattedData.our_policies[e].card_text_3.attribute_2_value}</div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="flexRow justify-content-center">
                                            <Link
                                              title={ i === 0 ? 'Next By Pacific Life' : 'SBLI Term'}
                                              to={ i === 0 ? '/next-by-pacific-life' : '/SBLI'}
                                              className="orangeBtn" >
                                                            Learn More
                                            </Link>
                                          </div>
                                        </> :
                                        <>
                                          <div className="policyDescription">
                                            {formattedData.our_policies[e].card_text.content}
                                          </div>

                                          <div className="flexColumn">
                                            <div className="flexRow justify-content-between mt16px">
                                              <div className="flexColumn">
                                                <div className="label">
                                                  {formattedData.our_policies.column_1.orange_label_1.content}
                                                </div>

                                                <div className="policyAmount">
                                                                    $2,000
                                                </div>
                                              </div>

                                              <div className="flexColumn">
                                                <div className="label">
                                                  {formattedData.our_policies.column_1.orange_label_2.content}
                                                </div>

                                                <div className="policyAmount">
                                                                    $500,000
                                                </div>
                                              </div>
                                            </div>

                                            <div className="flexRow justify-content-between mt16px">
                                              <div className="flexColumn">
                                                <div className="label">
                                                  {formattedData.our_policies.column_1.orange_label_3.content}
                                                </div>

                                                <div className="policyAmount">
                                                                    $30,000
                                                </div>
                                              </div>

                                              <div className="flexColumn">
                                                <div className="label">
                                                  {formattedData.our_policies.column_1.orange_label_4.content}
                                                </div>

                                                <div className="policyAmount">
                                                                    $589,093
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="flexRow justify-content-center">
                                            <Link to='/stillwater' title='Stillwater'>
                                              <button className="orangeBtn">
                                                            Learn More
                                              </button>
                                            </Link>
                                          </div>
                                        </>
                                      }
                                    </div>
                                  </div>
                                )}
            </div>
          </div>
        </div>
      </div>

      <div className="row ourPartners flexRow ">
        <div className="containerCustomStyles flexColumn">
          <h3 className="section-title text-center">
            {formattedData && formattedData.our_partners.column_1.title.content}
          </h3>

          <div className="flexRow justify-content-center">
            {formattedData &&
                                Object.keys( formattedData.our_partners ).map( ( e, i ) =>
                                  ( i == 1 ) && formattedData.our_partners[e]  && <div key={e} className="partner flexColumn">
                                    <div className="flexRow">
                                      <div className="imgBox">
                                        <img src={formattedData.our_partners[e].card_sub_title.attribute_1_value}
                                          alt={formattedData.our_partners[e].card_sub_title.attribute_2_value} className="m-0"/>
                                      </div>

                                      <div className="partnerName">
                                        {formattedData.our_partners[e].card_sub_title.content}
                                      </div>
                                    </div>

                                    <p className="partnerDesc">
                                      {formattedData.our_partners[e].card_text.content}
                                    </p>

                                    <div className="flexRow align-items-center mb11">
                                      <div className="grade">{formattedData.our_partners[e].grade_1.attribute_1_value}</div>

                                      <div className="gradeText"> {formattedData.our_partners[e].grade_1.attribute_2_value} </div>
                                    </div>

                                    <div className="flexRow align-items-center">
                                      <div className="grade">{formattedData.our_partners[e].grade_2.attribute_1_value}</div>

                                      <div className="gradeText"> {formattedData.our_partners[e].grade_2.attribute_2_value} </div>
                                    </div>
                                  </div>
                                )}
          </div>
        </div>
      </div>

      {isLifeInsurance ?

        <div className='extra-content'>
          <div className='container'>

            <div className='flexRow justify-content-center'>
              <div className='info-para'>
                <h2 className="section-title text-center">Who should buy life insurance policy?</h2>

                <p>
          If you’re 18 years of age and above, you can <strong> buy a life insurance policy </strong> – either for yourself or get a loved one insured. A life insurance policy is an affordable way of protecting the future of your loved ones. There are different types of

                  <strong> life insurance policies </strong> today. Pick your ideal one and be insured in minutes. Do it today because life insurance cost only up as you age. We’ll help you pick the right

                  <strong> life insurance plan </strong> and you’ll be set for decades to come.
                </p>
              </div>
            </div>

            <div className='justify-content-center info-para'>
              <h2 className="section-title text-center">
              How to choose the right life insurance plan?
              </h2>

              <ul className='bullets'>
                <li>	Simply get a
                  <strong> life insurance quote </strong> on Bubble
                </li>

                <li>	See if the coverage suits your family’s financial future
                </li>

                <li>	Make sure the premium fits your monthly budget
                </li>

                <li>	Choose the policy that works best for you
                </li>

                <li>	Get instantly approved (maybe without a medical exam)
                </li>
              </ul>
            </div>

            <div className='justify-content-center'>
              <h2 className="section-title text-center">
            What is the process of buying life insurance?
              </h2>

              <p>
          Answer a few easy questions, share a few details, and AI guidance system will give you a few options of policies for you to choose from. It’s that simple. You then buy pay for your life insurance policy and live peacefully knowing that your loved ones financial future is protected.
              </p>
            </div>

            <div className='justify-content-center info-para'>
              <h3 className="section-title text-center">
                  Life Insurance Nugget
              </h3>

              <p>
                  Buying a life insurance policy is easier than it has ever been. It’s best advised to buy a life insurance policy while you’re young because <strong> life insurance premiums </strong> can get more expensive with age. Beside yourself, you can

                <strong> buy affordable life insurance policy </strong> for seniors. Pick the right life insurance plan by getting a

                <strong> life insurance quote online </strong> with Bubble. You can buy a policy in a few steps and clicks by answering a few questions and getting instantly approved. For a

                <strong> life insurance policy with Bubble </strong>, you may not even need a medical exam, provided you’re a young and healthy individual. You can buy life insurance for your parents and children at an affordable cost. Get a life insurance quote online today and keep your loved ones protected for decades to come.
              </p>
            </div>

          </div>
        </div>
        :
        <div className='extra-content'>
          <div className="container">

            <div  className='info-para'>
              <h2 className="section-title text-center">
            Why the need for Home Insurance?
              </h2>

              <p>
                <strong>Home insurance</strong>  is necessary for anyone with a home to protect it from damage caused by natural perils like storm, hurricane, wildfire, and more. Home insurance also protects your home, property, and yourself from theft, flooding, identity theft, and cyberattack among others. If you are a homeowner, homeowners insurance with the right coverage is important to keep your home and everything you hold dear safe.
              </p>
            </div>


            <div className='info-para'>
              <h2 className="section-title text-center">
          How to choose the right Home Insurance policy?
              </h2>

              <ul className='bullets'>
                <li>	Check HomeScore to find the predicted <strong> home insurance premium </strong> in your county
                </li>

                <li>	Decide what you want coverage for – home structure, garage, gazebo, art, jewelry, fencing and more can be covered.
                </li>

                <li>	Zero in on the coverage amount you need – your homeowners insurance premium will be based on coverage.
                </li>

                <li>	Make sure you have access to customer service even after your home insurance purchase.
                </li>
              </ul>
            </div>

            <div className='info-para'>
              <h2 className="section-title text-center">
            What is the process of <strong> buying Homeowners Insurance </strong>?
              </h2>

              <ul className='bullets'>
                <li>	Begin your Home Insurance journey online </li>

                <li>	Get your <strong> home insurance quote </strong></li>

                <li>	Answer a few questions </li>

                <li>	Pick a <strong> home insurance policy </strong> from the options given </li>

                <li>	Get instantly approved </li>

                <li> sometimes without home inspection </li>
              </ul>
            </div>

            <div className='info-para'>
        If you’re buying a new home or upgrading your current home, the right <strong> homeowners insurance policy </strong> is important. The average homeowners insurance can cover some basic aspects to protect your home. But a comprehensive one like with Bubble, can protect not only your home structure but also aspects like cyberattacks, identity theft, and more. Get your

              <strong> homeowners insurance quote online </strong> today and get instantly approved. Your homeowners insurance coverage depends on what you want to protect. And your

              <strong> homeowners insurance premium </strong> will be based on that coverage. With Bubble, you can get the coverage you need now and add more later if you need to. If your home is going through a renovation or any upgrade, it’s time to check your

              <strong> homeowners insurance policy </strong> too. It could be time to upgrade it as well.
            </div>
          </div>
        </div>
      }

      <div className="plansForYou">
        <div className="orangeBackGround">
          <div>
                            Find out which plan is right for you
          </div>

          <div className="customizedPlan">
                            Policies are customized according to your needs.
                            We have simplified the process to help you find the right policy that fits your budget and loved ones.
          </div>

          <Link to="/" type="button" className="btnWhite">
                            Get your quote now
          </Link>
        </div>
      </div>

      <div className="row info-para">
        <div className="col-lg-2"></div>

        <div className="col-lg-8 d-md-12">
          <FaqSection  faqs={faqArray}/>
        </div>
      </div>

    </Layout >
  );
};

export default CommonPlan;

// Life insurance provides a tax-free lump sum of money, called the death benefit, to a designated beneficiary after the passing of the insured. One can choose the type of policy needed, number of years to last, coverage amount, and make
// premium payments to the insurance company. Life insurance coverage offers affordable financial protection – and invaluable peace of mind – to people whose loved ones depend on them.
